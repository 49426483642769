<template>
    <v-card tile elevation="4" class="pa-0">
        <v-toolbar short flat color="white">
            <v-toolbar-title class="blue--text">{{label}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="blue" @click="openCreateOrganizationDialog">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn icon color="blue" v-on="on">
                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                    </v-btn>
                    <!-- <v-tab v-on="on" class="blue--text">
                        <Avatar :attr="mainProfile" :size="36"/>
                        <font-awesome-icon icon="caret-down" class="pl-2 blue--text" fixed-width size="1x"/>
                    </v-tab> -->
                </template>
                <v-list class="ma-0 pa-0">
                    <v-list-item-group>
                    <v-list-item :to="{ name: 'service-admin-organization-settings' }">
                        <!-- <v-list-item-content> -->
                            <v-list-item-title>Settings</v-list-item-title>
                        <!-- </v-list-item-content> -->
                    </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-menu>
        </v-toolbar>
        <!-- <v-divider></v-divider> -->
        <v-list dense nav>
            <v-list-item v-if="list.length === 0">No organizations yet.</v-list-item>
            <v-list-item v-for="item in list" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                <v-list-item-content class="text-start">
                    <OrganizationListItem :attr="item" :link="!selectOne"></OrganizationListItem>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <CreateOrganizationDialog v-model="createOrganizationDialogVisible" @cancel="createOrganizationDialogVisible = false" @created="onCreatedOrganization"/>
    </v-card>
</template>

<script>
import OrganizationListItem from '@/components/list-item/OrganizationListItem.vue';
import CreateOrganizationDialog from '@/components/service-dashboard/CreateOrganizationDialog.vue';
import { isValidName, compact } from '@/sdk/input';
// import { client } from '@/client';

export default {
    components: {
        OrganizationListItem,
        CreateOrganizationDialog,
    },

    props: {
    },

    data() {
        return {
            list: [],
            createOrganizationDialogVisible: false,
            // create organization
            displayCreateOrganization: false,
            createOrganizationForm: null,
            newOrganizationName: null,
            newOrganizationNameRules: [
                (v) => !!v || 'Display name is required',
                (v) => !v || isValidName(compact(v)) || 'Organization name is required',
            ],
            // add organization
            addOrganizationChoices: [],
            addOrganizationId: null,
        };
    },

    computed: {
        id() {
            return this.policyId;
        },
        label() {
            if (this.selectOne) {
                return 'Select an organization';
            }
            switch (this.list.length) {
            case 0:
                return 'No organizations';
            case 1:
                return '1 organization';
            default:
                return `${this.list.length} organizations`;
            }
        },
    },

    watch: {
        // displayCreateOrganization(value) {
        //     if (value && (this.add || this.create)) {
        //         this.initAddOrganizationChoices();
        //     }
        // },
    },

    methods: {
        async loadOrganizationList() {
            try {
                this.$store.commit('loading', { loadOrganizationList: true });
                const response = await this.$client.main().service.getOrganizationList();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadOrganizationList failed', err);
            } finally {
                this.$store.commit('loading', { loadOrganizationList: false });
            }
        },
        onClickItem(organizationId) {
            // if (this.selectOne) {
            //     this.$emit('selected', { organizationId });
            // }
            this.$router.push({ name: 'organization-dashboard', params: { organizationId } });
        },
        openCreateOrganizationDialog() {
            this.createOrganizationDialogVisible = true;
        },
        onCreatedOrganization() {
            this.createOrganizationDialogVisible = false;
            this.loadOrganizationList();
        },
        // async createOrganization() {
        //     this.error = false;
        //     console.log('createOrganization');
        //     const request = {
        //         // name: this.name,
        //         name: this.newOrganizationName,
        //         // agreeToTerms: this.isAgreeToTermsChecked,
        //         // interactionId: this.interactionId, // will be present if organization arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the organization to an appropriate location after organization is created
        //     };
        //     const response = await this.$client.organization.create(request);
        //     console.log('createOrganization response: %o', response);
        //     const { isCreated, id, error } = response;
        //     if (isCreated) {
        //         const organization = { id, ...request };
        //         this.list.push(organization);
        //         this.$emit('created-organization', organization);
        //         this.$emit('added-organization', organization);
        //         this.displayCreateOrganization = false;
        //         this.newOrganizationName = null;
        //     } else if (error) {
        //         this.error = error;
        //     } else {
        //         this.error = 'Request failed';
        //     }
        // },
        // async addOrganization() {
        //     const organization = { id: this.addOrganizationId, label: this.addOrganizationChoices.find((item) => item.value === this.addOrganizationId).text };
        //     this.list.push(organization);
        //     this.$emit('added-organization', organization);
        //     this.displayCreateOrganization = false;
        //     this.addOrganizationId = null;
        // },
        // async initAddOrganizationChoices() {
        //     // create a map of which organization ids are already in the list
        //     const organizationMap = {};
        //     this.list.forEach((item) => {
        //         organizationMap[item.id] = true;
        //     });
        //     // when we show the add/create organization dialog, load the list of available organizations to add to the policy (list of all organizations less the ones already in the policy)
        //     const result = await this.$client.service.getOrganizationList();
        //     console.log(`OrganizationViewList.vue fetch organizations result: ${JSON.stringify(result)}`);
        //     if (result && result.list) {
        //         // filter the results to remove organizations already in the policy, then convert the available organizations to a choice list for the v-select [ { text, value }, ... ]
        //         this.addOrganizationChoices = result.list.filter((item) => !organizationMap[item.id]).map((item) => ({ text: item.name, value: item.id }));
        //     }
        // },
    },

    mounted() {
        this.loadOrganizationList();
    },
};
</script>
